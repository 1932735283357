<template>
  <article-page :menu="menu">
    <div class="training-content" id="my-password">
      <div class="_subtitle">
        Смена логина и пароля у себя
      </div>
      <span>
        Авторизоваться на сайте Вы можете 2 способами: используя быструю ссылку или используя логин и пароль.
      </span>
      <ul>
        <li>
          <b>Быстрая ссылка</b>
          <br /><br />
          <div>
            Быстрая ссылка - это индивидуальная ссылка для входа без пароля, отправленная Вам на почту при регистрации. Она действительна в течение 31 дня, после чего становится неактивной.
            Вы можете обновить быструю ссылку в личном кабинете пользователя. Для этого перейдите в
            <accessed-link :name="Tabs.Settings.Account" target="_blank"> Мой профиль </accessed-link>
            и нажмите кнопку "Обновить быструю ссылку для входа".
            После этого Вам на почту будет отправлено письмо, содержащее ссылку для входа в систему.
          </div>
        </li>
        <br />
        <li>
          <b>Логин и пароль</b>
          <br /><br />
          <div>
            Классический способ авторизации. В качестве логина используется адрес электронной почты, указанный при регистрации.
            Пароль для входа в систему приходит на почту после регистрации. При необходимости, пароль можно сменить в
            <accessed-link :name="Tabs.Settings.Account" target="_blank"> личном кабинете пользователя, </accessed-link>
            в разделе "Пароль". Для смены необходимо ввести текущий пароль и новый пароль.
            При корректном заполнении всех полей на экране появится уведомление об успешном изменении пароля.
          </div>
          <br />
          <div>
            Для смены логина нужно перейти в <accessed-link :name="Tabs.Settings.Account" target="_blank"> личный кабинет пользователя, </accessed-link>
            и указать в поле <b>логин</b> новую электронную почту. Обратите внимание, если Ваш логин совпадал с почтой для уведомлений, то изменятся оба значения.
            Если почта для уведомлений отличалась от логина, то она изменена не будет.
          </div>
        </li>
      </ul>
    </div>
    <div class="training-content" id="user-password">
      <div class="_subtitle">
        Смена логина и обновление быстрой ссылки у других пользователей
      </div>
      <span>
        Если вы обладаете правами Администратора и работаете соместно с другими сотрудниками (см.
        <accessed-link :name="Tabs.Training.UserManagement"> Совместная работа с тендерами</accessed-link>),
        Вы можете обновить быструю ссылку для входа остальным пользователям. Для этого перейдите в раздел
        <accessed-link :name="Tabs.Settings.UsersManagement" target="_blank"> Управление пользователями </accessed-link>
        и нажмите на иконку <ui-icon :icon="UiIconNames.Icon_Connection" :size="12" /> напротив нужного аккаунта.
        В открывшемся окне подтвердите отправку письма на почту. При успешном обновлении быстрой ссылки, на экране появится соответствующее уведомление.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt"  @click="openViewer(images, 0)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import { Tabs } from "@/router/tabs";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "PasswordArticle",
  components: {
    AccessedLink,
    ArticleImage,
    UiIcon,
    ArticlePage,
  },
  setup() {

    // todo
    // Авторизоваться на сайте Вы можете 3 способами: используя быструю ссылку, используя логин и пароль,
    // а так же с использованием быстрой авторизации через сторонние сервисы (yandex, google, vk и прочее).

    const menu = [
      { id: 'my-password', title: 'Смена логина и пароля у себя' },
      { id: 'user-password', title: 'Смена логина и пароля у других' },
    ]

    const images = [
      { src: 'settings/user-management/edit-auth-methods.png', alt: 'Обновление логина и быстрой ссылки у пользователя' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      openViewer,
      Tabs,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
